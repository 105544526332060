import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { LoadingService } from '../../services/loading.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgxSpinnerModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'nssf-investment-web';
  isLoading: boolean = false;
  loadingTimeout: any;

  constructor(
    private _loadingService: LoadingService,
    private _router: Router,
    private _spinnerService: NgxSpinnerService,
    private _titleService: Title,
    private _translateService: TranslateService
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._loadingService.forceStop();
        this._loadingService.setLoading(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this._loadingService.setLoading(false);
      }
    });
  }

  ngOnInit(): void {
    this._translateService.get('app_name').subscribe((text) => {
      this._titleService.setTitle(text);
    });
    this._loadingService.isLoading$.subscribe((isLoading: boolean) => {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
      }
      this.loadingTimeout = setTimeout(() => {
        if (isLoading) {
          this._spinnerService.show('pageLoading');
        } else {
          this._spinnerService.hide('pageLoading');
        }
        this.loadingTimeout = null;
      }, 200);
    });
  }
}
